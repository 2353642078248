import { graphql, useStaticQuery } from 'gatsby';
import CategoryHelper from '../helpers/Category';

// Only articles with PUBLISHED status are used.
const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
        skip: 0
        filter: {
          fileAbsolutePath: { regex: "/(posts)/" }
          frontmatter: { status: { eq: "PUBLISHED" } }
        }
      ) {
        nodes {
          frontmatter {
            title
            slug
            tags
            status
            category
            excerpt
            private
            metaDescription
            previewTitle
            canonicalurl
            ogpagetype
            author
            date
            image {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 300, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.allMdx.nodes.map(post => ({
    title: post.frontmatter.title,
    slug: post.frontmatter.slug,
    author: post.frontmatter.author,
    tags: post.frontmatter.tags,
    date: post.frontmatter.date,
    excerpt: post.frontmatter.excerpt,
    private: post.frontmatter.private,
    category: post.frontmatter.category,
    metaDescription: post.frontmatter.metaDescription,
    previewTitle: post.frontmatter.previewTitle,
    canonicalurl: post.frontmatter.canonicalurl,
    ogpagetype: post.frontmatter.ogpagetype,
    image: post.frontmatter.image,
    fileLocation: CategoryHelper.getCategoryLocation(
      post.frontmatter.category,
      post.frontmatter.slug
    ),
  }));
};

export default usePosts;
