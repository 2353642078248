const getCategoryLocation = (category, slug) => {
  const CategoryMap = {
    'apps & desktop software': 'apps-desktop-software',
    'cb updates': 'cb-updates',
    'digital service providers': 'digital-service-providers',
    'life bytes': 'life-bytes',
    'stores & websites': 'stores-websites',
    'tv & movies': 'tv-movies',
  };
  if (
    !category ||
    category === '' ||
    category === null ||
    category === undefined
  ) {
    return slug;
  }

  const categoryKey = CategoryMap[category];

  if (!categoryKey || categoryKey === null) {
    return slug;
  }

  return categoryKey + '/' + slug;
};

export default {
  getCategoryLocation
}