import React from 'react';
import Colors from './Colors';
import { css } from '@emotion/core';
import CategoryMap from '../constants/CategoryMap';
import { Link } from 'gatsby';

const ColoredCategory = ({ category }) => {
  const finalCategory = category => {
    const lowercaseCategory = category.toLowerCase();
    const categoryKey = CategoryMap.values[lowercaseCategory];
    let categoryColor = CategoryMap.colors[categoryKey];
    let categoryURL = CategoryMap.urls[categoryKey];

    if (categoryColor === undefined) categoryColor = Colors.categoryMatchError;
    if (categoryURL === undefined) categoryURL = '/';

    return {
      categoryColor,
      categoryURL,
    };
  };

  const categoryName = category => category.toUpperCase();

  const categoryData = finalCategory(category);

  return (
    <Link to={categoryData.categoryURL}>
      <span
        css={css`
          color: ${categoryData.categoryColor};
        `}
      >
        {categoryName(category)}
      </span>
    </Link>
  );
};

export default ColoredCategory;
