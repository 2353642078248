import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import ColoredCategory from './ColoredCategory';
import Colors from './Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross } from '@fortawesome/free-solid-svg-icons';

const PostPreview = ({ post }) => {
  const currentKey = 'poprev-' + post.slug;
  const title = (post.previewTitle) ? post.previewTitle : post.title;
  const backgroundImage = post.image && post.image.childImageSharp && post.image.childImageSharp.fluid.src;
  return (
    <li
      className="armory-card-wrapper"
      key={currentKey}
      css={css`
        display: -webkit-box;
        display: flex;
        padding: 1rem;
      `}
    >
      <div
        css={css`
          width: 300px;
          height: 540px;
          background-color: white;
          border-radius: 1.25rem;
          box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
          display: -webkit-box;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-direction: column;
          overflow: hidden;
        `}
      >
        <div
          css={css`
            width: 300px;
            height: 540px;

            background-color: white;
            border-radius: 1.25rem;
            box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            overflow: hidden;
          `}
        >
          <Link
            css={css`
              color: #5d6769;
              &:hover {
                color: ${Colors.main};
              }
            `}
            to={post.fileLocation}
          ></Link>
          <div
            css={css`
              position: relative;
              height: 305px;
              margin-bottom: 35px;
              border-top-left-radius: 14px;
              border-top-right-radius: 14px;
              background-image: url(${backgroundImage});
              background-repeat: no-repeat;
              background-position: center;
            `}
          >
            <Link
              css={css`
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                z-index: 999;
                &:hover {
                  pointer: cursor;
                }
              `}
              to={post.fileLocation}
            ></Link>
          </div>
          <div
            css={css`
              height: 314px;
            `}
          >
            <div
              css={css`
                text-transform: uppercase;
                text-align: center;
                font-size: 12px;
                font-weight: 700;
                margin-bottom: 3px;
              `}
            >
              <ColoredCategory category={post.category} />
            </div>
            <div
              css={css`
                font-size: 26px;
                font-weight: 900;
                margin-bottom: 5px;
                text-align: center;
                color: #5d6769;
              `}
            >
              <Link
                css={css`
                  color: #5d6769;
                  &:hover {
                    color: ${Colors.main};
                  }
                `}
                to={post.fileLocation}
              >
                {title}
              </Link>
            </div>
            <div
              css={css`
                padding: 10px;
                margin-bottom: 10px;
                color: #58574f;
                text-align: center;
              `}
            >
              {post.excerpt}
            </div>
          </div>
          <div
            css={css`
              color: white;
              font-weight: 700;
              border-bottom-left-radius: 14px;
              border-bottom-right-radius: 14px;
              background: ${Colors.main};
            `}
          >
            <div
              css={css`
                height: 60px;
                line-height: 60px;
                text-align: center;
              `}
            >
              <div
                css={css`
                  margin: auto;
                  font-variant: small-caps;
                  font-size: 1.25rem;
                  vertical-align: middle;
                  color: ${Colors.light};
                  &:hover {
                    text-decoration: underline;
                    color: ${Colors.light};
                  }
                `}
                className="link"
              >
                <Link
                  css={css`
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    font-size: 1rem;
                    line-height: 1.5;
                    color: ${Colors.light};
                    &:hover {
                      color: ${Colors.light};
                    }
                  `}
                  to={post.fileLocation}
                >
                  READ
                  <FontAwesomeIcon
                    css={css`
                      margin-left: 10px;
                    `}
                    icon={faCross}
                    size={'sm'}
                    width="10"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default PostPreview;
